//2dcolors
$tan:#FEE5C0;
$black:#020202;
$pink:#FEAEBA;
$darkblue:#00006E;
$cream:#FEBC48;
$lightblue:#017CFF;
$blue:#1F1CFB;
$salmon:#FB9985;
$red:#FB303D;
// the :export directive is the magic sauce for webpack
:export {
    tan:$tan;
    black:$black;
    pink:$pink;
    salmon:$salmon;
    blue:$blue;
    darkblue:$darkblue;
    cream:$cream;
    lightblue:$lightblue;
}