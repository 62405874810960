html {
  font-family: 'Apercu', sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.no-border{
  border-radius:0rem !important;
  border:none !important;
}

.btn-naked {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.btn-naked:focus {
  outline: none;
}

.apercu {
  font-family: 'Apercu', sans-serif;
}

.clearface {
  font-family: 'ClearfaceITCPro', sans-serif;
}

.z-9 {
  z-index: 9;
}

.link {
  text-decoration: underline;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.img-size {
  width: 20%;
  height: auto;
}

.max-width {
  max-width: 70%;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.s-1 {
  font-size: 0.5rem;
}

.s-2 {
  font-size: 1rem;
}

.s-3 {
  font-size: 1.5rem;
}

.s-4 {
  font-size: 2rem;
}

.fade.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1200ms;
}
.fade-appear {
  opacity: 0;
}
.fade-appear-active {
  opacity: 1;
  transition: opacity 1200ms;
}

.bounce:nth-child(1){
  animation: bounce 1600ms ease-in-out 10ms infinite;
}
.bounce:nth-child(2){
  animation: bounce 1600ms ease-in-out 400ms infinite;
}
.bounce:nth-child(3){
  animation: bounce 1600ms ease-in-out 10ms infinite;
}
@keyframes bounce {
  0%,100% {
    transform: translateY(0px);
  }
  20%,80% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-60px);
  }
}